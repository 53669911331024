;(($, window, document, undefined) => {
    $.extend(window.yunu, {
        message_visitor(data, obj){
            layui.use(['layer', 'form', 'table', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['80%', '60%'],
                    content: `<div class="pl10 pr10 pt20">
                        <div class="table_tool mb20">
                            <div>
                                <a class="layui-btn btn" href="javascript:;" onclick="yunu.message_visitor_add('${data.yunuMessageToken}')">新增</a>
                            </div>
                            <div class="layui-form">
                                <div class="layui-input-inline w334">
                                    <input class="layui-input" id="export_range_time" type="text" name="export_range_time" lay-verify="required">
                                </div>
                                <div class="layui-input-inline">
                                    <button class="layui-btn btn ml30" lay-submit="" lay-filter="export">导出</button>
                                </div>
                            </div>
                        </div>
                        <div class="message_visitor" id="message_visitor" lay-filter="message_visitor"></div>
                    </div>`,
                    success: function(layero, index){
                        yunu.table({
                            url: '/' + module_name + '/order/message_visitor',
                            where: {
                                yunuMessageToken: data.yunuMessageToken,
                            },
                            elem: '#message_visitor',
                            cols: [[
                                {field: 'index', title: '序号', align: 'center', type: 'numbers', width:60}
                                ,{field: 'name', title: '访客名称', align: 'center'}
                                ,{field: 'tel', title: '联系方式', align: 'center'}
                                ,{field: 'area', title: '区域', align: 'center'}
                                ,{field: 'browser', title: '浏览器', align: 'center'}
                                ,{field: 'ip', title: 'IP', align: 'center'}
                                ,{field: 'contents', title: '咨询内容', width: 300, templet: function (d) {
                                    if (d.contents.length == 0) {
                                        return '-';
                                    }
                                    var el = '';
                                    for (var i = 0; i < d.contents.length; i++) {
                                        el += '['+d.contents[i]['create_time']+']:'+d.contents[i]['content']+'<br>';
                                    }
                                    el += '<a href="javascript:;" lay-event="more">查看更多</a>';
                                    return el;
                                }}
                                ,{field: 'referer_search_engine', title: '来源', align: 'center'}
                                ,{field: 'referer_keyword', title: '关键词', align: 'center'}
                                ,{field: 'create_time', title: '提交时间', align: 'center'}
                                ,{field: 'state', title: '处理状态', align: 'center', templet: function (d) {
                                    return '<div><input type="checkbox" name="state" lay-skin="switch" lay-filter="state" '+(d.state == 1 ? 'checked': '')+'></div>';
                                }}
                                ,{title: '操作', align: 'center', width: 120, templet: function (d) {
                                    return '<a class="table_btn" href="'+d.href+'" target="_blank">查看链接</a>';
                                }}
                            ]],
                            done: function(res, curr, count){
                                $.each(res.data, function (i, v) {
                                    $('[lay-id="message_visitor"] [data-index="'+i+'"]').attr('data-id', v.nanoid);
                                });
                            }
                        }, {
                            table: 'message_visitor',
                            qsave: '/' + module_name + '/order/qsave_visitor',
                            switch: ['state'],
                            // search: true,
                            // search_btn: '#message_visitor_search_btn',
                            // search_input: '#message_visitor_search',
                            event: {
                                more: function (data, obj) {
                                    yunu.message_data(data, obj);
                                },
                            }
                        });

                        laydate.render({
                            elem: '#export_range_time',
                            type: 'datetime',
                            range: true
                        });

                        form.on('submit(export)', function({field}){
                            field.yunuMessageToken = data.yunuMessageToken;
                            yunu.post({
                                url: '/' + module_name + '/order/message_visitor_export',
                                field,
                                success: function (res) {
                                    yunu.msg(res.info);
                                    window.location.href = res.data;
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        message_data(data, obj){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['60%', '60%'],
                    content: `<div class="pl10 pr10 pt20">
                        <div class="message_data" id="message_data" lay-filter="message_data"></div>
                    </div>`,
                    success: function(layero, index){
                        yunu.table({
                            url: '/' + module_name + '/order/message_data',
                            where: {
                                yunuMessageToken: data.yunuMessageToken,
                                nanoid: data.nanoid,
                            },
                            elem: '#message_data',
                            cols: [[
                                {field: 'create_time', title: '创建时间', align: 'center'}
                                ,{field: 'content', title: '内容', align: 'center'}
                            ]]
                        });
                    }
                });
            });
        },
        message_visitor_add(yunuMessageToken){
            let tpl = `
                <form class="layui-form pt20 pb50">
                    <div class="layui-form-item">
                        <label class="layui-form-label">访客名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="name" lay-verify="required" />
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系方式</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="tel" lay-verify="required" />
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">咨询内容</label>
                        <div class="layui-input-block mr50">
                            <textarea class="layui-textarea" name="content" lay-verify="required"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">咨询时间</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" id="create_time" type="text" name="create_time" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;
            layui.use(['layer', 'form', 'table', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: tpl,
                    success: function(layero, index){
                        laydate.render({
                            elem: '#create_time',
                            type: 'datetime'
                        });

                        form.on('submit(*)', function({field}){
                            field.yunuMessageToken = yunuMessageToken
                            yunu.post({
                                url: '/' + module_name + '/order/message_visitor_add',
                                field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    table.reload('message_visitor');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        auto_reply(data, obj, callback = ()=>{}){
            let tpl = `
                <div class="pd20">
                    <div class="table_tool mb20">
                        <div>
                            <a class="layui-btn btn" href="javascript:;" onclick="yunu.auto_reply_editor({oid: ${data.id}})">新增</a>
                            <a class="layui-btn btn" id="auto_reply_close_editor" href="javascript:;">设置</a>
                        </div>
                    </div>
                    <div class="table_mult">
                        <div class="table" id="table_auto_reply" lay-filter="table_auto_reply"></div>
                    </div>
                </div>
            `;
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['80%', '80%'],
                    content: tpl,
                    success: function(layero, index){
                        yunu.table({
                            elem: '#table_auto_reply',
                            url: '/' + module_name + '/order/auto_reply',
                            where: {oid: data.id},
                            cols: [[
                                {field: 'index', title: '序号', align: 'center', type: 'numbers', width:60}
                                ,{field: 'content_strip_tags', title: '自动回复', align: 'center'}
                                ,{field: 'delay', title: '延迟发送', align: 'center', width: 150, templet: function (d) {
                                    return d.delay + '秒';
                                }}
                                ,{title: '操作', align: 'center', width: 300, templet: function (d) {
                                    return '<a class="table_btn" href="javascript:;" lay-event="edit">修改</a><a class="table_btn" href="javascript:;" lay-event="delete">删除</a>';
                                }}
                            ]]
                        }, {
                            table: 'table_auto_reply',
                            event: {
                                edit: function (data, obj) {
                                    yunu.auto_reply_editor(data, obj);
                                },
                                delete: function (data, obj) {
                                    layer.confirm('您是否确认删除？', function(index){
                                        yunu.post({
                                            url: '/' + module_name + '/order/auto_reply_delete',
                                            field: {id: data.id},
                                            success: function (res) {
                                                yunu.msg(res.info);
                                                table.reload('table_auto_reply');
                                            }
                                        });
                                        layer.close(index);
                                    });
                                },
                            }
                        });

                        $('#auto_reply_close_editor').on('click', function function_name(argument) {
                            yunu.auto_reply_close_editor(data.ext, obj, callback, index);
                        })
                    }
                });
            });
        },
        auto_reply_editor(data, obj, callback = ()=>{}){
            let tpl = `
                <form class="layui-form pt20 pb50">
                    <div class="layui-form-item">
                        <label class="layui-form-label">自动回复</label>
                        <div class="layui-input-block mr50">
                            <textarea id="editor" name="content" required lay-verify="required" style="height: 300px;">${data?.content ?? ''}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">延迟发送</label>
                        <div class="layui-input-inline slider_input w334">
                            <div class="slider" id="slider"></div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;
            layui.use(['layer', 'form', 'table', 'slider'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let slider = layui.slider;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: tpl,
                    success: function(layero, index){
                        UE.delEditor('editor');
                        var ue = UE.getEditor('editor', {
                            zIndex: 99999999,
                            toolbars: [[
                                'source', 'fontsize', 'bold', 'forecolor'
                            ]]
                        });
                        let delay = data?.delay ?? 0;
                        slider.render({
                            elem: '#slider',
                            value: delay,
                            min: 0,
                            max: 60,
                            input: true,
                            change: function (value) {
                                delay = value
                            }
                        });

                        form.on('submit(*)', function({field}){
                            field.delay = delay
                            field.oid = data.oid
                            if (data.id) {
                                field.id = data.id
                            }
                            yunu.post({
                                url: '/' + module_name + '/order/auto_reply_editor',
                                field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    table.reload('table_auto_reply');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        auto_reply_close_editor(data, obj, callback = ()=>{}, open_index){
            let tpl = `
                <form class="layui-form pt20 pb50">
                    <div class="layui-form-item content_box">
                        <label class="layui-form-label">顾客回复则停止发送</label>
                        <div class="layui-input-inline w334">
                            <input type="checkbox" name="auto_reply_close" lay-skin="switch" value="1" ${data.auto_reply_close == 1 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn ml30" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: tpl,
                    success: function(layero, index){
                        form.render();
                        form.on('submit(*)', function({field}){
                            if (data.yunuMessageToken) {
                                field.yunuMessageToken = data.yunuMessageToken;
                            }
                            if (!field.auto_reply_close) {
                                field.auto_reply_close = 0;
                            }
                            yunu.post({
                                url: '/' + module_name + '/order/ext_update',
                                field,
                                success: function (res) {
                                    obj.update({auto_reply_close: field.auto_reply_close});
                                    layer.close(index);
                                    layer.close(open_index);
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        ai_reply(data, obj, callback = ()=>{}){
            let tpl = `
                <div class="pd20">
                    <div class="table_tool mb20">
                        <div>
                            <a class="layui-btn btn" href="javascript:;" onclick="yunu.ai_reply_editor({oid: ${data.id}})">新增</a>
                            <a class="layui-btn btn" id="ai_reply_auto_reply_editor" href="javascript:;">设置</a>
                        </div>
                    </div>
                    <div class="table_mult">
                        <div class="table" id="table_ai_reply" lay-filter="table_ai_reply"></div>
                    </div>
                </div>
            `;
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['80%', '80%'],
                    content: tpl,
                    success: function(layero, index){
                        yunu.table({
                            elem: '#table_ai_reply',
                            url: '/' + module_name + '/order/ai_reply',
                            where: {oid: data.id},
                            cols: [[
                                {field: 'index', title: '序号', align: 'center', type: 'numbers', width:60}
                                ,{field: 'keyword', title: '关键词', align: 'center'}
                                ,{field: 'typeVal', title: '类型', align: 'center'}
                                ,{field: 'content_strip_tags', title: '自动回复', align: 'center'}
                                ,{title: '操作', align: 'center', width: 300, templet: function (d) {
                                    return '<a class="table_btn" href="javascript:;" lay-event="edit">修改</a><a class="table_btn" href="javascript:;" lay-event="delete">删除</a>';
                                }}
                            ]]
                        }, {
                            table: 'table_ai_reply',
                            event: {
                                edit: function (data, obj) {
                                    yunu.ai_reply_editor(data, obj);
                                },
                                delete: function (data, obj) {
                                    layer.confirm('您是否确认删除？', function(index){
                                        yunu.post({
                                            url: '/' + module_name + '/order/ai_reply_delete',
                                            field: {id: data.id},
                                            success: function (res) {
                                                yunu.msg(res.info);
                                                table.reload('table_ai_reply');
                                            }
                                        });
                                        layer.close(index);
                                    });
                                },
                            }
                        });

                        $('#ai_reply_auto_reply_editor').on('click', function function_name(argument) {
                            yunu.ai_reply_auto_reply_editor(data.ext, obj, callback, index);
                        })
                    }
                });
            });
        },
        ai_reply_editor(data, obj, callback = ()=>{}){
            let tpl = `
                <form class="layui-form pt20 pb50">
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="keyword" lay-verify="required" value="${data?.keyword ?? ''}" />
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">类型</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="type" value="0" title="包含" ${data?.type == 0 || !data?.type ? 'checked' : ''} lay-verify="required">
                            <input type="radio" name="type" value="1" title="等于" ${data?.type == 1 ? 'checked' : ''} lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">自动回复</label>
                        <div class="layui-input-block mr50">
                            <textarea id="editor" name="content" required lay-verify="required" style="height: 300px;">${data?.content ?? ''}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;
            layui.use(['layer', 'form', 'table', 'slider'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: tpl,
                    success: function(layero, index){
                        form.render();
                        UE.delEditor('editor');
                        var ue = UE.getEditor('editor', {
                            zIndex: 99999999,
                            toolbars: [[
                                'source', 'fontsize', 'bold', 'forecolor'
                            ]]
                        });
                        form.on('submit(*)', function({field}){
                            field.oid = data.oid
                            if (data.id) {
                                field.id = data.id
                            }
                            yunu.post({
                                url: '/' + module_name + '/order/ai_reply_editor',
                                field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    table.reload('table_ai_reply');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        ai_reply_auto_reply_editor(data, obj, callback = ()=>{}, open_index){
            let tpl = `
                <form class="layui-form pt20 pb50">
                    <div class="layui-form-item">
                        <label class="layui-form-label">无匹配自动应答</label>
                        <div class="layui-input-inline w334 content_box">
                            <input type="checkbox" name="ai_reply_auto_reply" lay-filter="ai_reply_auto_reply" lay-skin="switch" value="1" ${data.ai_reply_auto_reply == 1 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item" id="ai_reply_auto_reply_content" ${data.ai_reply_auto_reply == 0 ? 'style="display: none;"' : ''}>
                        <label class="layui-form-label">自动回复</label>
                        <div class="layui-input-block mr50">
                            <textarea id="editor" name="ai_reply_auto_reply_content" style="height: 300px;">${data?.ai_reply_auto_reply_content ?? ''}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: tpl,
                    success: function(layero, index){
                        form.render();
                        UE.delEditor('editor');
                        var ue = UE.getEditor('editor', {
                            zIndex: 99999999,
                            toolbars: [[
                                'source', 'fontsize', 'bold', 'forecolor'
                            ]]
                        });
                        form.on('switch(ai_reply_auto_reply)', function(data){
                            if (data.elem.checked) {
                                $('#ai_reply_auto_reply_content').show().closest('.layui-layer-content').height(546);
                            } else {
                                $('#ai_reply_auto_reply_content').hide();
                            }
                        });
                        form.on('submit(*)', function({field}){
                            if (data.yunuMessageToken) {
                                field.yunuMessageToken = data.yunuMessageToken;
                            }
                            if (!field.ai_reply_auto_reply) {
                                field.ai_reply_auto_reply = 0;
                            }
                            yunu.post({
                                url: '/' + module_name + '/order/ext_update',
                                field,
                                success: function (res) {
                                    obj.update({
                                        ai_reply_auto_reply: field.ai_reply_auto_reply,
                                        ai_reply_auto_reply_content: field.ai_reply_auto_reply_content,
                                    });
                                    layer.close(index);
                                    layer.close(open_index);
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        reception_reply(data, obj, callback = ()=>{}){
            let tpl = `
                <div class="pd20">
                    <div class="table_tool mb20">
                        <div>
                            <a class="layui-btn btn" href="javascript:;" onclick="yunu.reception_reply_editor({oid: ${data.id}})">新增</a>
                        </div>
                    </div>
                    <div class="table_mult">
                        <div class="table" id="table_reception_reply" lay-filter="table_reception_reply"></div>
                    </div>
                </div>
            `;
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['80%', '80%'],
                    content: tpl,
                    success: function(layero, index){
                        yunu.table({
                            elem: '#table_reception_reply',
                            url: '/' + module_name + '/order/reception_reply',
                            where: {oid: data.id},
                            cols: [[
                                {field: 'index', title: '序号', align: 'center', type: 'numbers', width:60}
                                ,{field: 'keyword', title: '关键词', width: 200, align: 'center'}
                                ,{field: 'content_strip_tags', title: '快捷菜单', align: 'center'}
                                ,{title: '操作', align: 'center', width: 300, templet: function (d) {
                                    return '<a class="table_btn" href="javascript:;" lay-event="edit">修改</a><a class="table_btn" href="javascript:;" lay-event="delete">删除</a>';
                                }}
                            ]]
                        }, {
                            table: 'table_reception_reply',
                            event: {
                                edit: function (data, obj) {
                                    yunu.reception_reply_editor(data, obj);
                                },
                                delete: function (data, obj) {
                                    layer.confirm('您是否确认删除？', function(index){
                                        yunu.post({
                                            url: '/' + module_name + '/order/reception_reply_delete',
                                            field: {id: data.id},
                                            success: function (res) {
                                                yunu.msg(res.info);
                                                table.reload('table_reception_reply');
                                            }
                                        });
                                        layer.close(index);
                                    });
                                },
                            }
                        });
                    }
                });
            });
        },
        reception_reply_editor(data, obj, callback = ()=>{}){
            let tpl = `
                <form class="layui-form pt20 pb50">
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="keyword" lay-verify="required" value="${data?.keyword ?? ''}" />
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">快捷菜单</label>
                        <div class="layui-input-block mr50">
                            <textarea id="editor" name="content" required lay-verify="required" style="height: 300px;">${data?.content ?? ''}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;
            layui.use(['layer', 'form', 'table', 'slider'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: tpl,
                    success: function(layero, index){
                        UE.delEditor('editor');
                        var ue = UE.getEditor('editor', {
                            zIndex: 99999999,
                            toolbars: [[
                                'source', 'fontsize', 'forecolor', 'link'
                            ]]
                        });
                        form.on('submit(*)', function({field}){
                            field.oid = data.oid
                            if (data.id) {
                                field.id = data.id
                            }
                            yunu.post({
                                url: '/' + module_name + '/order/reception_reply_editor',
                                field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    table.reload('table_reception_reply');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        notice_setting(data, obj, batch){
            const tpl = `
                <form class="layui-form pt25 pb20">
                    <div class="tips mb20 ml20 mr20">
                        填写客户接受询盘的联系方式，只能填写唯一联系方式
                    </div>
                    ${data ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">接收邮箱</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="notice_email" value="${data?.notice_email ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">接收短信</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="notice_sms" value="${data?.notice_sms ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">接收微信</label>
                        <div class="layui-input-inline w334 flex flex_aic" id="qrcode">
                            <input class="layui-input" type="text" value="${data?.notice_wx ?? ''}" readonly>
                            <a class="layui-btn" href="javascript:;">${data?.openid ? '更换绑定' : '绑定微信号'}</a>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>
            `;

            layui.use(['layer', 'form', 'table'], function(){
                const layer = layui.layer;
                const form = layui.form;
                const table = layui.table;

                layer.open({
                    type: 1,
                    area: ['566px'],
                    content: tpl,
                    success: function () {
                        form.render();
                        form.on('submit(*)', function({ field }){
                            yunu.post({
                                url: '/' + module_name + '/Order/editor',
                                field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info);
                                    table.reload('table');
                                }
                            });
                            return false;
                        });

                        $('#qrcode a').on('click', function () {
                            yunu.qrcode(data, function (access_token) {
                                data.access_token = access_token;
                            });
                        });
                    }
                });
            });
        },
        qrcode(data, callback) {
            layui.use(['layer', 'table'], function(){
                const layer = layui.layer;
                const table = layui.table;

                if (data.wx_url === null) {
                    yunu.msg('未填写公众号AppID');
                    return;
                }

                var timer;
                layer.open({
                    type: 1,
                    title: false,
                    area: ['360px','360px'],
                    content: `<img src='${data.wx_url}' width="360" />`,
                    success: function (layero, index) {
                        clearInterval(timer);
                        timer = setInterval(() => {
                            $.post('/' + module_name + '/Order/check_access_token_change', {
                                id: data.id,
                            }).then((res) => {
                                if (res.data.access_token !== data.access_token) {
                                    clearInterval(timer);
                                    $('#qrcode').find('input').val(res.data.notice_wx).end().find('a').html('更换绑定');
                                    layer.close(index);
                                    callback(res.data.access_token);
                                    table.reload('table');
                                    layer.alert('绑定成功');
                                }
                            });
                        }, 3000)
                    },
                    end() {
                        clearInterval(timer);
                    }
                });
            });
        },
        qrcode_user(wx_AppID) {
            layui.use(['layer'], function(){
                const layer = layui.layer;

                yunu.post({
                    url: '/' + module_name + '/Push/wx_url',
                    field: {
                        wx_AppID
                    },
                    success: function (res) {
                        let data = res.data
                        if (data.wx_url === null) {
                            yunu.msg('未填写公众号AppID');
                            return;
                        }

                        var timer;
                        layer.open({
                            type: 1,
                            title: false,
                            area: ['360px','360px'],
                            content: `<img src='${data.wx_url}' width="360" />`,
                            success: function (layero, index) {
                                clearInterval(timer);
                                timer = setInterval(() => {
                                    $.post('/' + module_name + '/Push/check_access_token_change').then((res) => {
                                        if (res.data.access_token !== data.access_token) {
                                            clearInterval(timer);
                                            $('#qrcode').find('input').val(res.data.notice_wx).end().find('a').html('更换绑定');
                                            layer.close(index);
                                            layer.alert('绑定成功', function () {
                                                window.location.reload();
                                            });
                                        }
                                    });
                                }, 3000)
                            },
                            end() {
                                clearInterval(timer);
                            }
                        });
                    }
                });
            });
        },
    });

    $(() => {

    });

})(jQuery, window, document);
